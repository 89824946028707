import React from "react";
import PlanRastrillo from "../app/plan_rastrillo";
import LayoutUser from "../layouts/layout.user";
import { AuthContext } from "../contexts/authentication";


export default function Home() {
    const auth = React.useContext(AuthContext);
    
    if(auth.authorized) {
        return <PlanRastrillo/>
    }
    return null;
}