import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Card, CardHeader, Divider, Grid } from '@mui/material';
import { AuthContext } from '../../contexts/authentication';
import { UbicacionesService } from '../../services/ubicaciones.service';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import { API_UBICACIONES } from "../../models/services";
import LinearProgress from '@mui/material/LinearProgress';
import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import Cancel from '@mui/icons-material/Cancel';

const style = {
    position: 'absolute' as 'absolute',
    maxWidth: 500,
    width: "100%",
    bgcolor: 'background.paper',
    boxShadow: 24,
  };

export function DownloadUbicaciones(props: {downloaded: () => void, status: any, setStatus: any}) {
    const [progress, setProgress] = React.useState(0);
    const [maxProgress, setMaxProgress] = React.useState(0);
    const [data, setData] = React.useState<API_UBICACIONES | null>();
    const porcentaje = (progress / maxProgress * 100);
    const status = props.status;
    const setStatus = props.setStatus;

    function downloadCallback(type: "loaded", data: API_UBICACIONES, size: number):void;
    function downloadCallback(type: "progress", progress: number, maxSize?: number):void;
    function downloadCallback(type: "error"):void;
    function downloadCallback(type: "loaded" | "progress" | "error", progressOrData?: number | API_UBICACIONES, maxSizeOrSize?: number): void {
        setStatus(type);

        switch(type) {
            case "loaded":
                setProgress(maxSizeOrSize!);
                setMaxProgress(maxSizeOrSize!);
                setData(progressOrData as API_UBICACIONES);
                props.downloaded();
                break;
            
            case "error":
                break;

            case "progress":
                setMaxProgress(maxSizeOrSize ?? 50000000);
                setProgress(progressOrData as number);
                break;
        }
    }

    React.useEffect(() => {
        UbicacionesService.DownloadData(downloadCallback)
    }, [true])

    switch(status) {
        case "progress":
            return (
                <Box>
                    
                    <LinearProgress color="secondary" variant="determinate" value={porcentaje} />
                    {progress} / {maxProgress} Kbits
                </Box>
            )

        case "error":
            return (
                <Box>
                    <Typography color="error">Hubo un error al intentar descargar los datos</Typography>
                    <Button onClick={()=>UbicacionesService.DownloadData(downloadCallback)} color="secondary">Reintentar</Button>
                </Box>
            )

        case "loaded":
            return (
                <Box>
                    <Typography color="success.main">Descarga exitosa</Typography>
                </Box>
            )
            
        default:
            return (
                <Box>
                    <LinearProgress color="secondary"/>
                </Box>
            )
    }
}

export default function TransitionsModal() {
    const [downloaded, setDownloaded] = React.useState(false);
    const [downloading, setDownloading] = React.useState(false);
    const [status, setStatus] = React.useState<"loaded" | "progress" | "error" | "starting">("starting");
    const [OpenOutdatePackage, setOpenOutdatePackage] = React.useState(UbicacionesService.outdatedPackages);
    const auth = React.useContext(AuthContext);
    const open = auth.mode === null || ((auth.mode === "offline" || auth.mode === "auto") && !UbicacionesService.downloadedPackages && !downloaded);

  return (
    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        onClose={() => {
            const mode = localStorage.getItem("app-mode");

            switch(mode) {
                case "offline":
                case "online":
                case "auto":
                    auth.setMode(mode);
                    break;
            }
        }}
        
        open={open || OpenOutdatePackage}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        style={{display:"flex", justifyContent:"center", alignItems:"center"}}
      >
        <Fade in={open || OpenOutdatePackage}>
            <Card sx={style}>
                {auth.mode === null &&
                    <>
                        <CardHeader sx={{textAlign:"center"}} title="Modo App"/>
                        <Divider/>
                        {/* <Grid container p={2} gap={1} justifyContent="center" textAlign="center">
                            <Grid item xs={12} md={5.8}>
                                <Box py={2}>
                                    <Typography variant="h5">Offline</Typography>
                                    <Divider/>
                                    <Box py={1}>
                                        <Typography>
                                            El modo <strong>offline</strong> ofrece las posibilidades de ejecutar la aplicación idependiente, sin la
                                            necesidad de permanecer conectado a la red.<br/><br/>

                                            Nota: Es necesario realizar una descarga del <b>Paquete Offline</b> para que pueda funcionar correctamente.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            
                            <Grid item xs={12} md={5.8}>
                                <Box p={2}>
                                    <Typography variant="h5">Online</Typography>
                                    <Divider/>
                                    <Box py={1}>
                                        <Typography>
                                            EL modo <strong>online</strong> (como su nombre lo indíca) para poder funcionar debes estar conectado a la red.<br/><br/>

                                            Este modo permite mostrar el contenido más actualizado, debido a que se consulta directamente a nuestros servidores.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid> */}
                        <Grid container p={2} gap={2}>
                            <Grid item xs={12} md={12}>
                                <Button 
                                    color="secondary" 
                                    variant='contained' 
                                    fullWidth 
                                    onClick={() => auth.setMode("auto")}
                                    startIcon={<CenterFocusStrongIcon/>}
                                >
                                    Automático
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Button 
                                    color="info" 
                                    variant='contained' 
                                    fullWidth 
                                    onClick={() => auth.setMode("online")}
                                    startIcon={<WifiIcon/>}
                                >
                                    Online
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Button 
                                    color="error" 
                                    variant='contained' 
                                    fullWidth 
                                    onClick={() => auth.setMode("offline")}
                                    startIcon={<WifiOffIcon/>}
                                >
                                    Offline
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                }
                {(auth.mode === "offline" || auth.mode === "auto") && !UbicacionesService.downloadedPackages &&
                    <>
                        <CardHeader title={
                            <Box display="flex" justifyContent="space-between">
                                <Typography variant="h5">Descargar Paquetes</Typography>
                                <Button color="secondary" startIcon={<KeyboardBackspaceIcon/>} disabled={(downloading && status !== "error") } onClick={() => {auth.setMode(null)}}>
                                    Atras
                                </Button>
                            </Box>
                        }/>
                        <Divider/>
                        <Box p={2} justifyContent="center">
                            <Typography>
                                Para continuar es necesario descargar el paquete de las <strong>Ciudades</strong> para poder usar la aplicación en
                                modo <strong>{auth.mode}</strong>
                            </Typography>
                            {downloading &&
                                <Box p={2}>
                                    <DownloadUbicaciones
                                        status={status}
                                        setStatus={setStatus}
                                        downloaded={() => setTimeout(() => setDownloaded(true), 2000)}
                                    />
                                </Box>
                            }
                            {!downloading &&
                                <Box display="flex" justifyContent="center" pt={4} onClick={() => setDownloading(true)}>
                                    <Button color="secondary" variant='contained' fullWidth>Iniciar Descarga</Button>
                                </Box>
                            }
                        </Box>
                    </>
                }
                {(auth.mode === "offline" || auth.mode === "auto") && OpenOutdatePackage &&
                    <>
                        <CardHeader title={
                            <Box display="flex" justifyContent="space-between">
                                <Typography variant="h5">Paquetes Desactualizados</Typography>
                                <Button color="secondary" startIcon={<Cancel/>} disabled={downloading && status !== "error"} onClick={() => {setOpenOutdatePackage(false)}}>
                                    Cerrar
                                </Button>
                            </Box>
                        }/>
                        <Divider/>
                        <Box p={2} justifyContent="center">
                            <Typography>
                                Se recomienda actualizar los paquetes de las <strong>Ciudades</strong> para poder disfrutar de los últimas actualizaciones
                            </Typography>
                            {downloading &&
                                <Box p={2}>
                                    <DownloadUbicaciones
                                        status={status}
                                        setStatus={setStatus}
                                        downloaded={() => setTimeout(() => {setDownloaded(true); setOpenOutdatePackage(false);}, 2000)}
                                    />
                                </Box>
                            }
                            {!downloading &&
                                <Box display="flex" justifyContent="center" pt={4} onClick={() => setDownloading(true)}>
                                    <Button color="secondary" variant='contained' fullWidth>Iniciar Descarga</Button>
                                </Box>
                            }
                        </Box>
                    </>
                }
            </Card>
        </Fade>
    </Modal>
  );
}