import * as React from 'react';
import Button from '@mui/material/Button';
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';

export default function Notification(props: {children: React.ReactNode}) {
  return (
    <SnackbarProvider maxSnack={3}>
      {props.children}
    </SnackbarProvider>
  );
}