import React from "react";
import { RatingInput } from "../../../models/components";
import { FormStructContext } from "../../../contexts/form_struct";
import { Box, Rating, Typography } from "@mui/material";


export default function RatingInputComponent(props: RatingInput) {
    const formData = React.useContext(FormStructContext);

    function HandlerOnChange(ev: React.SyntheticEvent<Element, Event>, value: number | null) {
        formData.setVar(props.name, value)
    }

    React.useEffect(() => {
        if(props.value && (formData.vars[props.name] === undefined)) {
            formData.setVar(props.name, props.value);
        }
    })
    
    return (
        <Box>
            <Typography component="legend">Controlled</Typography>
            <Rating
                name={props.name}
                size="large"
                value={Number(formData.vars[props.name] ?? 0)}
                onChange={HandlerOnChange}
            />
        </Box>
    )
}