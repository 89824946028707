import { Box, Button, Divider, Typography } from "@mui/material";
import { FormPageProps, InputElement, PropsFormElement } from "../../models/components";
import React from "react";
import { FormStructContext } from "../../contexts/form_struct";
import TextInputComponent from "../inputs/text";
import NumericInputComponent from "../inputs/numeric";
import SliderInputComponent from "../inputs/slider";
import SwitchInputComponent from "../inputs/switch";
import CheckboxInputComponent from "../inputs/checkbox";
import RatingInputComponent from "../inputs/rating";
import SelectInputComponent from "../inputs/select";
import ButtonInputComponent from "../inputs/button";
import GroupInputElement from "../inputs/group";
import PhoneInputComponent from "../inputs/phone";
import DateInputComponent from "../inputs/date";
import RadioInputComponent from "../inputs/radio";
import LoadingInputComponent from "../inputs/loading";
import "./index.css";

export function FormElement(props: PropsFormElement) {
    const formData = React.useContext(FormStructContext);
    const [element, setElement] = React.useState<InputElement>({...props.element});

    const setInput = function(element: InputElement) {
        setTimeout(() => setElement(element), 0);
    }

    React.useEffect(() => {
        if(props.beforeRendering) {
            props.beforeRendering(element, setInput, formData.vars);
        }
    }, [true])

    console.log(console.log(formData.vars))
    
    switch(element.type) {
        case "text":
            return <TextInputComponent {...element}/>

        case "numeric":
            return <NumericInputComponent {...element}/>

        case "slider":
            return <SliderInputComponent {...element}/>

        case "switch":
            return <SwitchInputComponent {...element}/>

        case "checkbox":
            return <CheckboxInputComponent {...element}/>

        case "rating":
            return <RatingInputComponent {...element}/>

        case "select":
            return <SelectInputComponent {...element}/>

        case "button":
            return <ButtonInputComponent {...element}/>

        case "phone":
            return <PhoneInputComponent {...element}/>

        case "date":
            return <DateInputComponent {...element}/>

        case "radio":
            return <RadioInputComponent {...element}/>

        case "loading":
            return <LoadingInputComponent {...element}/>
            
        case "group":
            return <GroupInputElement beforeRendering={props.beforeRendering} {...element}/>
        default:
            return null;
    }
}

export default function FormPage(props: FormPageProps) {
    const [displayError, setDisplayError] = React.useState(false);
    const [msgError, setMsgError] = React.useState<string>("");
    const formData = React.useContext(FormStructContext);
    const {
        title,
        subtitle,
        next,
        back,
        id,
        jump,
        ...inputElement
    } = props.formPageData;

    React.useEffect(() => {
        if(jump) formData.reserveJump(jump)
    }, [true])

    function GetElementRequired(input: typeof inputElement): InputElement | undefined {
        if("required" in input && input.required) {
            if(formData.vars[input.name] === undefined) return input;
        }
        else if("children" in input) {
            return input.children.find(child => GetElementRequired(child));
        }
    }
    
    function HandlerOnSubmit(ev: React.FormEvent<HTMLFormElement>) {
        ev.preventDefault();

        const element = GetElementRequired(inputElement);

        if(element && "name" in element) {
            setMsgError(`El campo ${element.label ?? element.name} es requerido`)
            setDisplayError(true);
        }
        else {
            setDisplayError(false);
            formData.Next(); // next page
        }
    }

    let buttons;

    if(back && next) {
        buttons = (
            <Box display="flex" justifyContent="space-between">
                <Button variant="contained" sx={{float: "left", minWidth:"100px"}} onClick={formData.Back}>{back}</Button>
                <Button type="submit" variant="contained" sx={{float: "right", minWidth:"100px"}}>{next}</Button>
            </Box>
        )
    }
    else if(back) {
        buttons = (
            <Box display="flex" justifyContent="start-flex">
                <Button type="submit" variant="contained" sx={{float: "right", minWidth:"100px"}}>{next}</Button>
            </Box>
        )
    }
    else if(next) {
        buttons = (
            <Box display="flex" justifyContent="end-flex">
                <Button type="submit" variant="contained" sx={{float: "right", minWidth:"100px"}}>{next}</Button>
            </Box>
        )
    }
    
    return (
        <Box component="form" onSubmit={HandlerOnSubmit}>
            
            {(title || subtitle) && 
                <Box py={2}>
                    {title &&
                    <Typography variant="h4" color="primary">{title}</Typography>
                    }
                    {subtitle &&
                        <Typography variant="subtitle1" color="GrayText">{subtitle}</Typography>
                    }
                    <Divider/>
                </Box>
            }
            <Box py={1}>
                <FormElement element={inputElement} beforeRendering={props.beforeRendering}/>
            </Box>
            {Boolean(msgError) &&
                <Box p={1} className={displayError ? "show-error" : "hidde-error"}>
                    <Typography color="error">{msgError}</Typography>
                </Box>
            }
            {buttons}
        </Box>
    )
}