import axios from "axios";
import React from "react";
import { environment } from "../environments/enviroment.prod";
import { AuthInterface, UserData } from "../models/authentication";
import { LocalStorageService } from "../services/localstorage.service";
import { UbicacionesService } from "../services/ubicaciones.service";
import adminData from "../assets/admin.json";
import Swal from "sweetalert2";

export function useAuthentication(): AuthInterface {
    const [user, setUser] = React.useState<UserData | null>(LocalStorageService.Get<UserData>("userAuth") || null);
    const [loading, setLoading] = React.useState(false);
    const [mode, _setMode] = React.useState<"online" | "offline" | "auto" | null>(localStorage.getItem("app-mode") as any ?? "auto");
    const admin = adminData.find(ad => ad.admin === user?.email)?.observer;
    
    if(mode) UbicacionesService.setMode(mode);
    
    function Login(lgEmail: string, lgPassword: string, remenber: boolean): Promise<boolean> {
        return new Promise(resolve => {
            setLoading(true);
            
            setTimeout(async () => {
                try {
                    const response = await axios.get<UserData>(`${environment.crm.url}staff/?email=${encodeURIComponent(lgEmail)}&pass=${encodeURIComponent(lgPassword)}`);

                    console.log(response.data);
                    if(response.status === 200 && response.data.status !== false) {
                        setUser(response.data);

                        console.log(remenber)
                        if(remenber) {
                            LocalStorageService.Set("userAuth", response.data);
                        }
                        resolve(true);
                    }
                    else {
                        Swal.fire({
                            icon: "error",
                            title: "Usuario y/o Clave inválida",
                            text: "Intenta ingresar tus dados correctamente",
                            confirmButtonText: "Aceptar"
                        })
                    }
                }
                catch(_err) {
                    console.log(_err);
                    Swal.fire({
                        icon: "error",
                        title: "Error de conexión",
                        text: "Asegurate que estas conectado a internet",
                        confirmButtonText: "Aceptar"
                    })
                    resolve(false);
                }
                setLoading(false);
            }, 1000)
        })
    }

    function Logout() {
        setMode(null);
        setUser(null);
        localStorage.removeItem("app-mode");
        LocalStorageService.Remove("userAuth");
    }

    function setMode(newMode: "online" | "offline" | "auto" | null) {
        if(newMode) {
            localStorage.setItem("app-mode", newMode);
        }
        _setMode(newMode);
    }
    
    if(user) {
        return {
            authorized: true,
            user,
            admin,
        
            mode,
            setMode,
            loading,
            Login,
            Logout
        }
    }
    return {
        authorized: false,
        
        admin,
        mode,
        setMode,
        loading,
        Login,
        Logout
    }
}