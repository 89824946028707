import { EventDownloadUbicaciones } from "../models/services";
import { NavigatorService } from "./navigator.service";
import UbicacionesOfflineClass from "./ubicaciones/offline.service";
import UbicacionesOnlineClass from "./ubicaciones/online.service";

class UbicacionesClass {
    private status: "online" | "offline";

    private online: UbicacionesOnlineClass;
    private offline: UbicacionesOfflineClass;
    private mode: "auto" | "offline" | "online";
    
    get downloadedPackages(): boolean {
      return Boolean(this.offline.downloadedPackages);
    }

    get outdatedPackages(): boolean {
        return Boolean(this.offline.outdatedPackages);
    }
  
    constructor() {
        this.mode = "auto";
        this.status = "offline";
        this.online = new UbicacionesOnlineClass();
        this.offline = new UbicacionesOfflineClass();

        setInterval(this.HandlerService.bind(this), 3000)
    }

    // onChangeStatus?(status: "online" | "offline"): void;

    HandlerService() {
        const prevStatus = this.status;

        switch(this.mode) {
            case "offline":
            case "online":
                this.status = this.mode;
                break;

            case "auto":
                if(NavigatorService.onLine) this.status = "online";
                else this.status = "offline";
                break;
        }

        // if(prevStatus !== this.status && this.onChangeStatus) this.onChangeStatus(this.status); 
    }

    async DownloadData(callback: EventDownloadUbicaciones) {
      return await this.offline.DownloadData(callback);
    }

    setMode(mode: "auto" | "offline" | "online") {
        this.mode = mode;
    }

    GetStatus() {
      return this.status;
    }
  
    async GetCiudades() {
        try {
            return await this[this.status].GetCiudades();
        }
        catch(err) {
            if(this.mode === "auto") return await this["offline"].GetCiudades();
            else throw(err);
        }
    }
  
    async GetOnlyCiudad(id_ciudad: string) {
        try {
            return await this[this.status].GetOnlyCiudad(id_ciudad);
        }
        catch(err) {
            if(this.mode === "auto") return await this["offline"].GetCiudades();
            else throw(err);
        }
    }
  
    async GetEstados() {
        try {
            return await this[this.status].GetEstados();
        }
        catch(err) {
            if(this.mode === "auto") return await this["offline"].GetEstados();
            else throw(err);
        }
    }
        
    async GetEstado(id_ciudad: string) {
        try {
            return await this[this.status].GetEstado(id_ciudad);
        }
        catch(err) {
            if(this.mode === "auto") return await this["offline"].GetEstado(id_ciudad);
            else throw(err);
        }
    }
        
    async GetOnlyEstado(id_estado: string) {
        try {
            return await this[this.status].GetOnlyEstado(id_estado);
        }
        catch(err) {
            if(this.mode === "auto") return await this["offline"].GetOnlyEstado(id_estado);
            else throw(err);
        }
    }
  
    async GetMunicipios(id_estado?: string) {
        try {
            return await this[this.status].GetMunicipios(id_estado);
        }
        catch(err) {
            if(this.mode === "auto") return await this["offline"].GetMunicipios(id_estado);
            else throw(err);
        }
    }
  
    async GetOnlyMunicipio(id_municipio: string) {
        try {
            return await this[this.status].GetOnlyMunicipio(id_municipio);
        }
        catch(err) {
            if(this.mode === "auto") return await this["offline"].GetOnlyMunicipio(id_municipio);
            else throw(err);
        }
    }
  
    async GetSectores(id_municipio?: string) {
        try {
            return await this[this.status].GetSectores(id_municipio);
        }
        catch(err) {
            if(this.mode === "auto") return await this["offline"].GetSectores(id_municipio);
            else throw(err);
        }
    }
  
    async GetOnlySector(id_sector: string) {
        try {
            return await this[this.status].GetOnlySector(id_sector);
        }
        catch(err) {
            if(this.mode === "auto") return await this["offline"].GetOnlySector(id_sector);
            else throw(err);
        }
    }
  
    async GetUbicacionesSector(id_sector?: string) {
        try {
            return await this[this.status].GetUbicacionesSector(id_sector);
        }
        catch(err) {
            if(this.mode === "auto") return await this["offline"].GetUbicacionesSector(id_sector);
            else throw(err);
        }
    }
  
    async GetUbicaciones(id_sector?: string) {
        try {
            return await this[this.status].GetUbicaciones(id_sector);
        }
        catch(err) {
            if(this.mode === "auto") return await this["offline"].GetUbicaciones(id_sector);
            else throw(err);
        }
    }
  
    async GetNomenclaturas(id_ubi?: string) {
        try {
            return await this[this.status].GetNomenclaturas(id_ubi);
        }
        catch(err) {
            if(this.mode === "auto") return await this["offline"].GetNomenclaturas(id_ubi);
            else throw(err);
        }
    }
  
    async GetOnlyUbicacion(id_ubicacion: string) {
        try {
            return await this[this.status].GetOnlyUbicacion(id_ubicacion);
        }
        catch(err) {
            if(this.mode === "auto") return await this["offline"].GetOnlyUbicacion(id_ubicacion);
            else throw(err);
        }
    }
  
    async GetOnlyNomenclatura(id_nomenclatura: string) {
        try {
            return await this[this.status].GetOnlyNomenclatura(id_nomenclatura);
        }
        catch(err) {
            if(this.mode === "auto") return await this["offline"].GetOnlyNomenclatura(id_nomenclatura);
            else throw(err);
        }
    }
  
    async GetOnlyUbicacionZona(id_ubicacion: string) {
        try {
            return await this[this.status].GetOnlyUbicacionZona(id_ubicacion);
        }
        catch(err) {
            if(this.mode === "auto") return await this[this.status].GetOnlyUbicacionZona(id_ubicacion);
            else throw(err);
        }
    }
  
    async GetUbicacionesZonas(id_sector: string) {
        try {
            return await this[this.status].GetUbicacionesZonas(id_sector);
        }
        catch(err) {
            if(this.mode = "auto") return await this[this.status].GetUbicacionesZonas(id_sector);
            else throw(err);
        }
    }
}

export const UbicacionesService = new UbicacionesClass();