import React, { ChangeEvent } from "react";
import { TextInput } from "../../../models/components";
import { TextField } from "@mui/material";
import { FormStructContext } from "../../../contexts/form_struct";

export default function TextInputComponent(props: TextInput) {
    const formData = React.useContext(FormStructContext);

    function HandlerOnChange(ev: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        formData.setVar(props.name, ev.target.value);
    }

    React.useEffect(() => {
        if(props.value && (formData.vars[props.name] === undefined)) {
            formData.setVar(props.name, props.value);
        }
    }, [])
    
    return (
        <TextField 
            name={props.name}
            fullWidth
            label={props.label}
            value={formData.vars[props.name]}
            onChange={HandlerOnChange}
            required={props.required}
        />
    )
}