import { Box } from "@mui/material";
import { GroupInput, InputElement } from "../../../models/components";
import { FormElement } from "../../form_page";
import { FormStructContextInterface } from "../../../models/contexts";


export default function GroupInputElement(props: GroupInput & {beforeRendering?: (input: InputElement, setInput: (newInput: InputElement) => void, vars: FormStructContextInterface["vars"]) => void}) {
    const {beforeRendering, ...propsData} = props;
    const direcction = props.direcction === "horizontal" ? "row" : "column";
    
    return (
        <Box display="flex" flexDirection={direcction} gap={2}>
            {propsData.children.map((child, index) =>
                <FormElement key={String(index)} element={{...propsData, ...child}} beforeRendering={beforeRendering}/>
            )}
        </Box>
    )
}