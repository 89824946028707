import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { SelectInput } from "../../../models/components";
import React from "react";
import { FormStructContext } from "../../../contexts/form_struct";
import {CircularProgress} from "@mui/material";


export default function SelectInputComponent(props: SelectInput) {
    const formData = React.useContext(FormStructContext);

    const options = props.options.map((opt, index) => ({...opt, value: String(index), label: opt.label ?? opt.value}))
    
    function HandlerOnChange(ev: SelectChangeEvent<unknown>) {
        const option = props.options[Number(ev.target.value)];

        if(option.jump) formData.reserveJump(option.jump);
        formData.setVar(props.name, option.value);
    }

    const value = props.options.findIndex(i => i.value === formData.vars[props.name]);
    
    React.useEffect(() => {
        if(props.value && (formData.vars[props.name] === undefined)) {
            formData.setVar(props.name, props.value);
        }
    }, [])

    return (
        <FormControl fullWidth>
            {props.label &&
                <InputLabel>
                    {props.label}
                </InputLabel>
            }
            <Select
                value={value}
                label={props.label}
                onChange={HandlerOnChange}
                required={props.required}
            >
                {options.map((opt, index) =>
                    <MenuItem key={String(index)} value={index.toString()} children={opt.label}/>  
                )}
                {props.loading &&
                    <Box py={1} display="flex" justifyContent="center" alignItems="center">
                        <CircularProgress size={15}/>
                    </Box>
                }
            </Select>
        </FormControl>
    )
}