import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { LayoutUserProps } from '../models/layouts';

import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import { Divider, ThemeProvider, createTheme } from '@mui/material';
import { LocalStorageService } from '../services/localstorage.service';

import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { AuthContext } from '../contexts/authentication';
import { IonRouterLink } from '@ionic/react';
import AppModeModal from '../components/app_mode';
import { useSnackbar } from 'notistack';
import { UbicacionesService } from '../services/ubicaciones.service';
import { Link } from 'react-router-dom';

import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import SenddingData from '../components/sending_data';
import { NavigatorService } from '../services/navigator.service';
import { AppConfig } from '../app.config';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}));

const themes = {
    night: createTheme({
        palette: {
            primary: {
                main: "#222"
            },
            // background: {
            //     paper: "#222"
            // },
            // divider: "#666",
            // error: {
            //     main: "#f88"
            // },
            
            // text: {
            //     primary: "#eee",
            //     secondary: "#eee",
            //     disabled: "#555"
            // },
            
            mode: 'dark'
        }        
    }),
    light: createTheme({
        palette: {
            primary: {
                main: "#fff",
                contrastText: "#30f"
            }
        }
    })
}

type TypeThemes = keyof (typeof themes);

export default function LayoutUser(props: LayoutUserProps) {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [themeMode, __setThemeMode] = React.useState<TypeThemes>(LocalStorageService.Get<TypeThemes>("themeMode") || "light");
  const snackbar = useSnackbar();
  const auth = React.useContext(AuthContext);

  const settings = [
    {
        display: "Modo de App",
        subDisplay: <Typography style={{position:"absolute", bottom: "0px", left: "56px", fontSize: "13px"}} color={auth.mode === "auto" ? "secondary" : (auth.mode === "offline" ? "error" : "#46a")}>{auth.mode}</Typography>,
        icon: <DisplaySettingsIcon color="info"/>
    },
    {
        display: "Cerrar Sesión",
        icon: <ExitToAppIcon color="error"/>
    }
    ];

    function setThemeMode(theme: string) {
        LocalStorageService.Set("themeMode", theme);
        __setThemeMode(theme as any);
    }

  const pages = [
    {
        display: "INICIO",
        href: "/home"
    }
  ];

  if(auth.admin) {
    pages.push({
        display: "ADMIN",
        href: "/admin"
    })
  }

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (type?: string) => {
    setAnchorElUser(null);
    switch(type) {
        case "Cerrar Sesión":
            auth.Logout();
            break;

        case "Modo de App":
            // auth.setMode(null);
            auth.setMode(null);
            // if(auth.mode === "auto") {
            //     auth.setMode("offline");
            //     snackbar.enqueueSnackbar(<>Se ha cambiado el modo de la app a: &nbsp;<Typography color="error">Offline</Typography></>)
            // }
            // else if(auth.mode === "offline") {
            //     auth.setMode("online");
            //     snackbar.enqueueSnackbar(<>Se ha cambiado el modo de la app a: &nbsp;<Typography color="dodgerblue">Online</Typography></>)
            // }
            // else {
            //     auth.setMode("auto");
            //     snackbar.enqueueSnackbar(<>Se ha cambiado el modo de la app a: &nbsp;<Typography color="secondary">Automático</Typography></>)
            // }
            break;
    }
  };

  const sx={width:"100%", height:"100%", position:"relative"};

  function onChangeStatus(newMode: "online" | "offline") {
    if(newMode === "online") {
        snackbar.enqueueSnackbar({variant:"success", action: "Te encuentras conectado a internet"});
    }
    else if(newMode === "offline") {
        snackbar.enqueueSnackbar({variant:"error", action: "No te encuentras conectado a internet"});
    }
  }

  React.useEffect(() => {
    NavigatorService.onChangeStatus = onChangeStatus;
  }, [])

  return (
    <Box sx={sx}>
        <ThemeProvider theme={themes[themeMode]}>
            <Box sx={{display:"flex", flexDirection:"column", height: "100vh", justifyContent:"stretch", justifyItems:"stretch"}}>
                <AppBar position="static">
                    <Container maxWidth="xl">
                        <Toolbar disableGutters>
                            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                                <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                                    <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="inherit"
                                    >
                                    <MenuIcon />
                                    </IconButton>
                                    <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                    sx={{
                                        display: { xs: 'block', md: 'none' },
                                    }}
                                    >
                                    {pages.map((page) => (
                                        <Link to={page.href} style={{textDecoration:"none"}}>
                                            <MenuItem key={page.display} onClick={handleCloseNavMenu} >
                                                <Typography textAlign="center" color="primary.contrassText" style={{textAlign:"center", minWidth:"150px"}}>
                                                    {page.display.toUpperCase()}
                                                </Typography>
                                            </MenuItem>
                                        </Link>
                                    ))}
                                    </Menu>
                                </Box>
                                
                                <Box display="flex" alignItems="center">
                                    <Tooltip title="Plan rastrillo">
                                        <MapsHomeWorkIcon sx={{ display: 'flex', mr: 1 }} />
                                    </Tooltip>
                                    <Typography
                                        variant="h6"
                                        noWrap
                                        component="a"
                                        href="/"
                                        sx={{
                                        mr: 2,
                                        display: { xs: 'none', sm: 'flex' },
                                        fontFamily: 'monospace',
                                        fontWeight: 700,
                                        letterSpacing: '.3rem',
                                        color: 'inherit',
                                        textDecoration: 'none',
                                        }}
                                    >
                                        Plan Rastrillo
                                    </Typography>

                                    <Box sx={{display: { xs: 'none', md: 'flex' } }}>
                                        {pages.map((page) => (
                                            <Link to={page.href} style={{textDecoration:"none"}}>
                                                <Button
                                                    key={page.display}
                                                    onClick={handleCloseNavMenu}
                                                    sx={{ py: 1, color: 'primary.contrastText', display: 'block'}}
                                                >
                                                    {page.display}
                                                </Button>
                                            </Link>
                                        ))}
                                    </Box>
                                </Box>
                                

                                

                                <Box display="flex" sx={{  }}>
                                    <MaterialUISwitch 
                                        sx={{ m: 1 }} 
                                        checked={themeMode === "night"} 
                                        onChange={themeMode === "night" ? () => setThemeMode("light") : () => setThemeMode("night")} 
                                    />
                                    <Tooltip title="Open settings">
                                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                        <Avatar alt={props.userData.name} src={props.userData.profile_image} />
                                    </IconButton>
                                    </Tooltip>
                                    <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={() => handleCloseUserMenu()}
                                    >
                                        <Box minWidth={250}>
                                            <Box display="flex" px={2} py={1} alignItems="center">
                                                <Box pr={2}>
                                                    <Avatar alt={props.userData.name} src={props.userData.profile_image} />
                                                </Box>
                                                <Box>
                                                    <Typography variant='h6'>{props.userData.name} {props.userData.lastname}</Typography>
                                                    <Typography variant='subtitle2'>{props.userData.email}</Typography>
                                                </Box>
                                            </Box>
                                            <Divider/>
                                            {settings.map((setting) => (
                                                <MenuItem key={setting.display} onClick={() => handleCloseUserMenu(setting.display)}>
                                                    <Box p={1} style={{display:"flex", alignItems:"center"}}>
                                                        <Box pr={1} display="flex" justifyContent="center" alignItems="center">
                                                            {setting.icon}
                                                        </Box>
                                                        <Box>
                                                            <Typography textAlign="center">{setting.display}</Typography>
                                                            {setting.subDisplay}
                                                        </Box>
                                                    </Box>
                                                </MenuItem>
                                            ))}
                                        </Box>
                                    </Menu>
                                </Box>
                            </Box>
                            




    {/*                         
                            <MapsHomeWorkIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                            <Box>
                            <Typography
                                variant="h5"
                                noWrap
                                component="a"
                                href=""
                                sx={{
                                mr: 2,
                                display: { xs: 'none', sm: 'flex', md: 'none' },
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                                }}
                            >
                                Plan Rastrillo
                            </Typography>
                            </Box>
                            <Box display="flex" sx={{flexGrow: 1}}>
                                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                    {pages.map((page) => (
                                        <Link to={page.href}>
                                            <Button
                                                key={page.display}
                                                onClick={handleCloseNavMenu}
                                                sx={{ py: 1, color: 'primary.contrastText', display: 'block' }}
                                            >
                                                {page.display}
                                            </Button>
                                        </Link>
                                    ))}
                                </Box>

                                <Box sx={{ flexGrow: 0 }}>
                                    <MaterialUISwitch 
                                        sx={{ m: 1 }} 
                                        checked={themeMode === "night"} 
                                        onChange={themeMode === "night" ? () => setThemeMode("light") : () => setThemeMode("night")} 
                                    />
                                    <Tooltip title="Open settings">
                                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                        <Avatar alt={props.userData.name} src={props.userData.profile_image} />
                                    </IconButton>
                                    </Tooltip>
                                    <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={() => handleCloseUserMenu()}
                                    >
                                    {settings.map((setting) => (
                                        <MenuItem key={setting} onClick={() => handleCloseUserMenu(setting)}>
                                        <Typography textAlign="center">{setting}</Typography>
                                        </MenuItem>
                                    ))}
                                    </Menu>
                                </Box>
                            </Box> */}
                        </Toolbar>
                    </Container>
                </AppBar>
                {/* <main style={{display:"block", width:"100%"}}> */}
                    {props.children}
                {/* </main> */}
            </Box>
            <AppModeModal/>
        </ThemeProvider>

        <SenddingData/>

        <Box sx={{
            display:"flex",
            position: "absolute", 
            bottom: "10px", 
            width: "100%", 
            paddingX: "10px", 
            justifyContent: "space-between"
        }}>
            <Box>
                <small style={{color:"white"}}>
                    <b>{AppConfig.version}</b>
                </small>
            </Box>
            <Box>
                <small style={{color:"white"}}>
                    <b>Plan Rastrillo</b>
                </small>
            </Box>
        </Box>
    </Box>
  );
}