import React from "react";
import { MuiTelInput } from 'mui-tel-input'
import { PhoneInput } from '../../../models/components';
import { FormStructContext } from "../../../contexts/form_struct";

export default function PhoneInputComponent(props: PhoneInput) {
    const formData = React.useContext(FormStructContext);
    
    function HandlerOnChange(newValue: string) {
        formData.setVar(props.name, newValue);
    }

    React.useEffect(() => {
        if(props.value && (formData.vars[props.name] === undefined)) {
            formData.setVar(props.name, props.value);
        }
    })
    
    return (
        <MuiTelInput 
            fullWidth
            value={String(formData.vars[props.name] ?? "")} 
            onChange={HandlerOnChange} 
            onlyCountries={["VE"]}
            required={props.required}
        />
    )
}