import axios from "axios";

class NavigatorClass {
    private __online: boolean;

    constructor() {
        this.__online = true;

        this.ping();
    }

    onChangeStatus?(status: "online" | "offline"): void;
    
    private ping = () => {
        var prevStatus = this.__online;

        setTimeout(() => {
            axios.get<any>("https://backend.thomas-talk.me/api/v1/find-any-info/thomas_cobertura/tmFallaGeneral/falla/1") // TODO DEBE CAMBIARSE POR UNA URL DISTINTA
                .then((res) => {
                    this.__online = Boolean(true);
                    console.log("PING", this.__online)
                    if(prevStatus !== this.__online && this.onChangeStatus) this.onChangeStatus(this.__online ? "online" : "offline"); 
                    this.ping()
                })
                .catch((data) => {
                    console.error(data);
                    this.__online = false;
                    console.log("PING", this.__online)

                    if(prevStatus !== this.__online && this.onChangeStatus) this.onChangeStatus(this.__online ? "online" : "offline"); 
                    this.ping()
                })
        }, 3000);
        // var pingserver = new Image();
        // pingserver.src = ;
     
        // setTimeout(() => {
        //     this.__online = Boolean(pingserver.height>0)

        //     if(prevStatus !== this.__online && this.onChangeStatus) this.onChangeStatus(this.__online ? "online" : "offline"); 
            
        //     setTimeout(this.ping, 2000);
        // }, 1000)
     }
    
    get onLine() {
        return this.__online;
    }
}

export const NavigatorService = new NavigatorClass();