import FormStruct from "../components/form_struct";
import PlanRastrilloData from "../assets/plan_rastrillo.json";
import React from "react";
import { UbicacionesService } from "../services/ubicaciones.service";
import Swal from "sweetalert2";
import { dbFullRastrillo } from "../services/dbFullRastrillo.service";
import { NavigatorService } from "../services/navigator.service";
import { Box } from "@mui/material";

export default function PlanRastrillo() {
    React.useEffect(() => {
        document.title = "Plan Rastrillo - App"
    })

    return (
        <Box sx={{position: "relative", width: "100%", height: "100%"}}>
            <Box display="flex" justifyContent="center" sx={{position: "absolute", width: "100%", paddingTop: "4%"}}>
                <img src="/fibex.png" alt="Fibextelecom"/>
            </Box>
            <FormStruct
                formData={PlanRastrilloData as any}
                onFinish={async (vars: any, finish, setFinishPage) => {
                    try {
                        const ciudad = vars["ciudad"].nombre_ciudad;
                        const municipio = vars["municipio"].nombre_mun;
                        const sector = vars["sector"].nombre_sector;
                        const zona = vars["zona"].nombre_ubicacion_zona;
                        
                        const estadoData = await UbicacionesService.GetEstado(vars["ciudad"].id_ciudad);
                        const estado = estadoData!.nombre_esta;
        
                        const direccionFiscal = ciudad + " " + estado + " " + municipio + " " + sector + " " + zona;
        
                        setFinishPage({
                            ...finish,
                            title:"Enviando...",
                            subtitle:"Espere un poco, se está enviando los datos",
                            type:"loading"
                        })
                        
                        const result = await dbFullRastrillo.AddRastrillo({
                            Vendedor: "fibex",
                            correo: "fibex@gmail.com",
                            FechaVisita: (new Date(vars["fecha_visita"])).toISOString(),
                            NombreCliente: vars["nombre_apellido"],
                            PhoneCliente: vars["numero_telefono"],
                            InteresEnContratar: vars["interes_contratar"],
                            PoseeServicioInternet: vars["posee_servicio_internet"],
                            ProvedorInternet: vars["nombre_proveedor"],
                            ContactoEfectivo: vars["contacto_efectivo"],
                            NoContactoEfectivo: vars["motivo_no_contacto"],
                            tipoResidencia: vars["tipo_residencia"],
                            NombreResidencia: vars["nombre_residencia"],
                            Zona: zona,
                            Ciudad: ciudad,
                            DireccionFiscal: direccionFiscal,
                        })

                        setTimeout(() => {
                            if(result) {
                                setFinishPage({
                                    ...finish,
                                    title:"Enviado",
                                    subtitle:"Los datos se han enviado correctamente",
                                    type:"button",
                                    label:"Actualizar",
                                    icon:"/reload.ico",
                                    click:{
                                        "reset":true
                                    }
                                })
                            }
                            else {
                                if(NavigatorService.onLine) {
                                    setFinishPage({
                                        ...finish,
                                        title:"Guardado",
                                        subtitle:"Los datos no se lograron enviar. Sin embargo se ha guardado para ser enviado posteriormente.",
                                        type:"button",
                                        label:"Actualizar",
                                        icon:"/reload.ico",
                                        click:{
                                            "reset":true
                                        }
                                    })
                                }
                                else {
                                    setFinishPage({
                                        ...finish,
                                        title:"Guardado",
                                        subtitle:"Los datos se han guardado con éxito. Recuerda que debes conectarte a internet para poder enviar los datos guardados localmente.",
                                        type:"button",
                                        label:"Actualizar",
                                        icon:"/reload.ico",
                                        click:{
                                            "reset":true
                                        }
                                    })
                                }
                            }
                        }, 1000)
                    }
                    catch(_err) {
                        setFinishPage({
                            ...finish,
                            title:"Error",
                            subtitle:"No se logró enviar los datos. Verifica tu conexión a internet e intentalo de nuevo.",
                            type:"button",
                            label:"Regresar",
                            click:{
                                jump:"back"
                            }
                        })
                    }
                }}
                beforeRendering={(input, newInput, vars: any) => {
                    switch(input.type) {
                        case "select": 
                            switch(input.name) {
                                case "ciudad":
                                    UbicacionesService.GetCiudades()
                                        .then(ciudades => {
                                            const options = ciudades.map(cityItem => ({value: cityItem, label: cityItem.nombre_ciudad}));

                                            newInput({
                                                ...input,
                                                options,
                                                loading: false
                                            })
                                        }) 
                                        .catch(_err => {
                                            console.error(_err)
                                            Swal.fire({
                                                title:"Error",
                                                icon:"error",
                                                text:"Hubo un error al intentar obtener las ciudades",
                                                footer:"Por favor revisa tu conexión a internet",
                                                confirmButtonText: "Aceptar"
                                            })
                                            newInput({
                                                ...input,
                                                loading: false
                                            })
                                        })
                                break;

                                case "municipio":
                                    UbicacionesService.GetMunicipios(vars["ciudad"].id_esta)
                                        .then(municipios => {
                                            const options = municipios.map(munItem => ({value: munItem, label: munItem.nombre_mun}));

                                            newInput({
                                                ...input,
                                                options,
                                                loading: false
                                            })
                                        }) 
                                        .catch(_err => {
                                            Swal.fire({
                                                title:"Error",
                                                icon:"error",
                                                text:"Hubo un error al intentar obtener los municipios",
                                                footer:"Por favor revisa tu conexión a internet",
                                                confirmButtonText: "Aceptar"
                                            })
                                            newInput({
                                                ...input,
                                                loading: false
                                            })
                                        })
                                break;

                                case "sector":
                                    UbicacionesService.GetSectores(vars["municipio"].id_mun)
                                        .then(sectores => {
                                            const options = sectores.map(secItem => ({value: secItem, label: secItem.nombre_sector}));

                                            newInput({
                                                ...input,
                                                options,
                                                loading: false
                                            })
                                        }) 
                                        .catch(_err => {
                                            Swal.fire({
                                                title:"Error",
                                                icon:"error",
                                                text:"Hubo un error al intentar obtener los sectores",
                                                footer:"Por favor revisa tu conexión a internet",
                                                confirmButtonText: "Aceptar"
                                            })
                                            newInput({
                                                ...input,
                                                loading: false
                                            })
                                        })
                                break;

                                case "zona": 
                                    UbicacionesService.GetUbicacionesZonas(vars["sector"].id_sector)
                                        .then(ubiZonas => {
                                            const options = ubiZonas.map(uz => ({value: uz, label: uz.nombre_ubicacion_zona}));

                                            newInput({
                                                ...input,
                                                options,
                                                loading: false
                                            })
                                        }) 
                                        .catch(_err => {
                                            Swal.fire({
                                                title:"Error",
                                                icon:"error",
                                                text:"Hubo un error al intentar obtener las zonas",
                                                footer:"Por favor revisa tu conexión a internet",
                                                confirmButtonText: "Aceptar"
                                            })
                                            newInput({
                                                ...input,
                                                loading: false
                                            })
                                        })
                                break;
                            }
                        break;
                    }
                }}
            />
        </Box>
    )
}