import React from "react";
import { AdminRastrilloContextInterface } from "../models/contexts";
import { dbFullRastrillo } from "../services/dbFullRastrillo.service";


export function useAdminRastrillo(email: string): AdminRastrilloContextInterface {
    const [elements, setElements] = React.useState<AdminRastrilloContextInterface["elements"]>([]);
    const [page, _setPage] = React.useState(0);
    const [limit, setLimit] = React.useState(5);
    const [loading, setLoading] = React.useState(false);

    const maxPage = Math.floor(elements.length/limit);
    const elementsPage = elements.slice(page*limit, page*limit+limit);
    const count = elements.length;

    function refresh() {
        setLoading(true);
        setTimeout(() => {
            dbFullRastrillo.GetAllByEmail(email)
                .then(elementsData => {
                    setElements(elementsData)
                    setLoading(false)
                })
                .catch(err => {
                    console.log(err)
                    setLoading(false);
                })
        }, 1000)
    }

    React.useEffect(refresh, [])

    function setPage(p: number) {
        if(p >= maxPage) {
            if(maxPage) p = maxPage-1;
            else p = 0;
        }
        _setPage(p);
    }
    
    function nextPage() {
        if(page < maxPage) {
            _setPage(page+1);
            // LoadElements();
        }
    }
    
    function backPage() {
        if(page > 0) {
            _setPage(page-1);
            // LoadElements();
        }
    }

    return {
        page,
        maxPage,
        count,
        limit,
        elements: elementsPage,
        loading,
        
        setPage,
        refresh,
        setLimit,
        nextPage,
        backPage
    }
}