import { Button } from "@mui/material";
import { ButtonInput } from "../../../models/components";
import { FormStructContext } from "../../../contexts/form_struct";
import React from "react";


export default function ButtonInputComponent(props: ButtonInput) {
    const formData = React.useContext(FormStructContext);
    
    function HandlerOnClick() {
        if(props.click) {
            if(props.click.jump) {
                formData.jumpTo(props.click.jump);
            }
            if(props.click.link) {
                document.location = props.click.link;
            }
            if(props.click.reset) {
                formData.reset();
            }
        }
    }
    
    return (
        <Button
            fullWidth
            variant="contained"
            disabled={props.disabled}
            children={props.label}
            startIcon={props.icon && <img src={props.icon} style={{maxWidth:"60px", maxHeight:"60px"}} alt=""/>}
            onClick={HandlerOnClick}
        />
    )
}