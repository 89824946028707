import React from "react";
import { dbFullRastrillo } from "../../services/dbFullRastrillo.service";
import { Box, Button, LinearProgress, Snackbar, SnackbarContent, Stack, Typography } from "@mui/material";
import { AuthContext } from "../../contexts/authentication";
import { useSnackbar } from "notistack";
import "./index.css";

export default function SenddingData() {
    const [progress, setProgress] = React.useState(0);
    const [maxProgress, setMaxProgress] = React.useState(0);
    // const [error, setError] = React.useState(false);
    // const [completed, setCompleted] = React.useState(false);
    const [closing, setClosing] = React.useState(false);
    const [sending, setSending] = React.useState(false);
    // const auth = React.useContext(AuthContext);
    const snackbar = useSnackbar();

    function Close() {
        setClosing(true);
        setTimeout(() => {
            setSending(false)
            setClosing(false);
        }, 1500);
    }

    dbFullRastrillo.onSendStart = function(size) {
        setSending(true);
        setProgress(0);
        setMaxProgress(size);
    }

    dbFullRastrillo.onSendProgress = function(progress) {
        setProgress(progress);
    }

    dbFullRastrillo.onSendError = function() {
        Close();
        snackbar.enqueueSnackbar({variant:"error", action: "Error de conexión"});
    }
    
    dbFullRastrillo.onSendEnd = function() {
        setProgress(maxProgress);
        setTimeout(() => Close(), 1000);
        snackbar.enqueueSnackbar({variant:"success", action: "Se ha enviado todos los datos con éxito"});
    }

    if(sending) {
        return (
            <SnackbarContent className={closing ? "end-sending" : "start-sending"} style={{position:"fixed", bottom: "30px", right: "10px"}}
                action={<Button color="secondary" sx={{display:{xs:"none", sm:"block"}}} onClick={() => setSending(false)}>Cerrar</Button>}
                message={
                <Box width="100%">
                    {progress === maxProgress &&
                        <Typography color="success">Enviado!</Typography>
                    }
                    {progress !== maxProgress &&
                        <Typography color="primary.contrassText">Enviado datos {progress} de {maxProgress}</Typography>
                    }
                    <LinearProgress variant="determinate" value={progress / maxProgress * 100} style={{width:"200px"}} color={progress === maxProgress ? "success" : "secondary"}/>
                </Box>
            }/>
        )
    }
    return null;
}