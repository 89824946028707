import { environment } from "../environments/enviroment.prod";
import { SeguridadDatos } from "./bscript";
import axios from "axios";

type dbFullTypeGet = "find-all-info" | "find-any-info" | "find-campos" | "find-in" | "find-pagination" | "find-between-dates" | "find-greater-lower" | "show-all-info" | "show-all-info-withbd";

type dbFullTypePost = "create-info" | "create-or-find";

type dbFullTypePut = "update-info";

type dbFullTypeDelete = "delete-info";

// thomas_ultra
// authenticationdb

export interface dbFullRequest<TypeData> {
    campo?: keyof TypeData
    campo2?: keyof TypeData
    valor?: TypeData[keyof TypeData]
    valor2?: TypeData[keyof TypeData]
    limit?: number
    page?: number
}

export interface dbFullRequestAnyQuery<TypeData> extends dbFullRequest<TypeData> {
    "x-data-query"?: string,
}

export interface dbFullRequestPost<TypeData> extends dbFullRequest<TypeData> {
    "x-keys-to-add-id"?: (keyof TypeData)[],
    "x-keys-of-arrays"?: string[],
    "x-relations"?: boolean
    "body": { [key in keyof TypeData]?: TypeData[key] }
}

export interface dbFullRequestPut<TypeData> extends dbFullRequest<TypeData> {
    "x-multiple-update"?: boolean,
    "x-elements-obj"?: string[],
    "x-attr-duplicate"?: string[],
    "body": { [key in keyof TypeData]?: TypeData[key] }
}

export interface dbFullRequestDelete<TypeData> extends dbFullRequest<TypeData> {

}

export type dbFullResult<Type> = Type & {
    createdAt: string
    updatedAt: string
}

export default class dbFull<TypeData> {
    readonly db: string;
    readonly table: string;
    readonly objs: (keyof TypeData)[];
    
    constructor(db: string, table: string, objs?: (keyof TypeData)[]) {
        this.db = db;
        this.table = table;
        this.objs = objs ?? [];
    }

    public async GET_ANY_QUERY(requestData: dbFullRequestAnyQuery<TypeData>): Promise<dbFullResult<TypeData>[]> {
        let {
            "x-data-query": xDataQuery,
            // ..._request
        } = requestData;

        // request = SeguridadDatos.EncrypObj(request);
        
        
        const headers = {
            TokenAuthPlataform: environment.dbFull.tokendbFull,
            db : SeguridadDatos.encrypt(this.db),
            table: SeguridadDatos.encrypt(this.table),
            type: SeguridadDatos.encrypt("any-queries"),
            Authorization: environment.dbFull.authdbFUll,
            "x-data-query": SeguridadDatos.encrypt(xDataQuery || ""),
            // ...requestData
        }

        const res = await axios.get(environment.dbFull.url, { headers: this.TransformObjectAnyToString(headers) });
      
        return res.data.map((item: any) => {
            try {
                return this.UntransformObjectObjectToString(item);
            }
            catch { return null }
        }).filter((i: any) => i !== null);
    }
    
    public async GET(type: dbFullTypeGet, requestData?: dbFullRequest<TypeData>): Promise<dbFullResult<TypeData>[]> {
        if(requestData) {
            requestData = SeguridadDatos.EncrypObj(requestData);
        }
        
        const headers = {
            TokenAuthPlataform: environment.dbFull.tokendbFull,
            db : SeguridadDatos.encrypt(this.db),
            table: SeguridadDatos.encrypt(this.table),
            type: SeguridadDatos.encrypt(type),
            Authorization: environment.dbFull.authdbFUll,
            ...requestData
        }

        const res = await axios.get(environment.dbFull.url, { headers: this.TransformObjectAnyToString(headers) });
      
        // console.log("REST", res)
        
        return res.data.map((item: any) => this.UntransformObjectObjectToString(item));
    }

    public async POST(type: dbFullTypePost, requestData: dbFullRequestPost<TypeData>): Promise<dbFullResult<TypeData>> {
        let {
            "x-keys-of-arrays": xKeysOfArray, 
            "x-keys-to-add-id": xKeysToAddId, 
            "x-relations": xRelations, 
            body,
            ...request
        } = requestData;

        request = SeguridadDatos.EncrypObj(request);
        
        const headers = {
            TokenAuthPlataform: environment.dbFull.tokendbFull,
            db : SeguridadDatos.encrypt(this.db),
            table: SeguridadDatos.encrypt(this.table),
            Authorization: environment.dbFull.authdbFUll,
            "x-keys-of-arrays": xKeysOfArray || [],
            "x-keys-to-add-id": xKeysToAddId || [],
            "x-relations": xRelations || false,
            ...request
        }
        
        const res = await axios.post(environment.dbFull.url + type, this.TransformObjectObjectToString(body), { headers: this.TransformObjectAnyToString(headers) });
      
        return this.UntransformObjectObjectToString(res.data);
    }

    public async PUT(type: dbFullTypePut, requestData: dbFullRequestPut<TypeData>): Promise<dbFullResult<TypeData>> {
        let {
            "x-multiple-update": xMultipleUpdate,
            "x-elements-obj": xElementsObj,
            "x-attr-duplicate": xAttrDuplicate,
            body, 
            ...request
        } = requestData;

        request = SeguridadDatos.EncrypObj(request);
        
        const headers = {
            TokenAuthPlataform: environment.dbFull.tokendbFull,
            db : SeguridadDatos.encrypt(this.db),
            table: SeguridadDatos.encrypt(this.table),
            Authorization: environment.dbFull.authdbFUll,
            "x-multiple-update": JSON.stringify(xMultipleUpdate || false),
            "x-elements-obj": JSON.stringify(xElementsObj || []),
            "x-attr-duplicate": JSON.stringify(xAttrDuplicate || []),
            ...request
        }

        const res = await axios.put(environment.dbFull.url + type, this.TransformObjectObjectToString(body), { headers: this.TransformObjectAnyToString(headers) });
      
        return this.UntransformObjectObjectToString(res.data);
    }

    public async DELETE(type: dbFullTypeDelete, requestData: dbFullRequestDelete<TypeData>): Promise<any> {
        if(requestData) {
            requestData = SeguridadDatos.EncrypObj(requestData);
        }
        
        const headers = {
            TokenAuthPlataform: environment.dbFull.tokendbFull,
            db : SeguridadDatos.encrypt(this.db),
            table: SeguridadDatos.encrypt(this.table),
            // type: SeguridadDatos.encrypt(type),
            Authorization: environment.dbFull.authdbFUll,
            ...requestData
        }

        // console.log(this.TransformObjectAnyToString(headers))

        const res = await axios.delete(environment.dbFull.url + type, { headers: this.TransformObjectAnyToString(headers) });
      
        return res.data;
    }

    private TransformObjectAnyToString<Type>(objTransform: Type): {[key in keyof Type]: string} {
        const objMaped: {[key in keyof Type]: string} = {} as any;
        
        for(let keyObj in objTransform) {
            objMaped[keyObj] = typeof objTransform[keyObj] === "string" ? String(objTransform[keyObj]) : JSON.stringify(objTransform[keyObj]);
        }

        return objMaped;
    }

    private TransformObjectObjectToString<Type>(objTransform: Type): {[key in keyof Type]: Type[key] extends Object ? string : Type[key]} {
        const objMaped: {[key in keyof Type]: Type[key] extends Object ? string : Type[key]} = {} as any;
        
        for(let keyObj in objTransform) {
            objMaped[keyObj] = (objTransform[keyObj] instanceof Object ? JSON.stringify(objTransform[keyObj]) : objTransform[keyObj]) as any;
        }

        return objMaped;
    }

    private UntransformObjectObjectToString<Type>(objUntransform: {[key in keyof Type]: Type[key] extends Object ? string : Type}): Type {
        const objMaped: Type = {} as any;

        for(let key in objUntransform) {
            if(this.objs.includes(key as any)) {
                objMaped[key] = JSON.parse(objUntransform[key] as string);
            }
            else {
                objMaped[key] = objUntransform[key] as any;
            }
        }

        return objMaped;
    }
}