import { environment } from "../environments/enviroment.prod";
import { SeguridadDatos } from "../lib/bscript";
import dbFull from "../lib/dbfull";
import IndexedDb from "../lib/indexedDB";
import { DatosRastrillo } from "../models/services";
import { NavigatorService } from "./navigator.service";

function Sleep(miliseconds: number) {
    return new Promise((res) => setTimeout(res, miliseconds));
}

class dbFullRastrilloClass extends dbFull<DatosRastrillo> {
    database: IndexedDb;
    sending: boolean;
    
    constructor() {
        super(SeguridadDatos.decrypt(environment.rastrillo.db), SeguridadDatos.decrypt(environment.rastrillo.table))

        this.sending = false;
        this.database = new IndexedDb("dbfull.rastrillo");

        this.database.createObjectStore(["rastrillo"]);
        this.HandlerChange();
    }

    onSendStart?(size: number): void;
    onSendEnd?(): void;
    onSendProgress?(progress: number, size: number): void;
    onSendError?(error: unknown): void;

    async Send(data: any[]) {
        try {
            if(!this.sending) {
                this.sending = true;

                if(this.onSendStart) this.onSendStart(data.length);

                await Sleep(2000);
                for(let x = 0; x < data.length; x++) {
                    await this.POST("create-info", {
                        body: data[x],
                        "x-keys-to-add-id":["IdRegistro"]
                    })
                    
                    this.database.deleteValue("rastrillo", data[x].id);

                    if(this.onSendProgress) this.onSendProgress(x, data.length);
                    await Sleep(500);
                }
    
                if(this.onSendEnd) this.onSendEnd();
                this.sending = false;
            }
        }
        catch(err) {
            if(this.onSendError) this.onSendError(err);
            this.sending = false;
        }
    }
    
    async HandlerChange() {
        try {
            if(NavigatorService.onLine) {
                const data = await this.database.getAllValue("rastrillo");
    
                if(data.length) {
                    await this.Send(data);
                }
            }
        }
        catch(_err) {  }
        
        setTimeout(this.HandlerChange.bind(this), 5000);
    }

    async AddRastrillo(data: DatosRastrillo) {
        const NA = "N/A" as const;

        try {
            await this.POST("create-info", {
                body: data,
                "x-keys-to-add-id":["IdRegistro"]
            })
            return true; // Se logró guardar con éxito!!!
        }
        catch(err) {
            this.database.putValue("rastrillo", {
                IdRegistro: "",
                Vendedor: data.Vendedor,
                correo: data.correo,
                FechaVisita: data.FechaVisita,
                NombreCliente: data.NombreCliente ?? NA,
                PhoneCliente: data.PhoneCliente ?? NA,
                InteresEnContratar: data.InteresEnContratar ?? NA,
                PoseeServicioInternet: data.PoseeServicioInternet ?? NA,
                ProvedorInternet: data.ProvedorInternet ?? NA,
                ContactoEfectivo: data.ContactoEfectivo ?? NA,
                NoContactoEfectivo: data.NoContactoEfectivo ?? NA,
                tipoResidencia: data.tipoResidencia ?? NA,
                NombreResidencia: data.NombreResidencia ?? NA,
                Zona: data.Zona ?? NA,
                Ciudad: data.Ciudad ?? NA,
                DireccionFiscal: data.DireccionFiscal ?? NA
            });

            return false; // significa que no se logró enviar, sin embargo se intentará luego!!!
        }
    }

    async GetAllByEmail(email: string) {
        const emails = ["fibex@gmail.com"];

        return (await this.GET_ANY_QUERY({
            "x-data-query": `SELECT * FROM ${this.db}.${this.table} WHERE correo in (${emails.map(e => `'${e}'`).join(",")})`
        })).sort((data: any, data2: any) => {
            try {
                return Number(new Date(data2.FechaVisita)) - Number(new Date(data.FechaVisita))
            }
            catch(err) {
                return 0;
            }
        })
    }

    // async GetDataByEmails(page: number, limit: number, email: string) {
    //     const emails = ["fibex@gmail.com"];

    //     const data = await this.GET_ANY_QUERY({
    //         "x-data-query": `SELECT * FROM ${this.db}.${this.table} WHERE correo in (${emails.map(e => `'${e}'`).join(",")})`
    //     })

    //     return data.slice(page*limit, page*limit+limit)
    // }

    // async GetCountsDataByEmails(email: string): Promise<number> {
    //     const emails = ["fibex@gmail.com"];

    //     const data: any = await this.GET_ANY_QUERY({
    //         "x-data-query": `SELECT COUNT(*) FROM ${this.db}.${this.table} WHERE correo in (${emails.map(e => `'${e}'`).join(",")})`
    //     })

    //     return data[0]["COUNT(*)"];
    // }
}

export const dbFullRastrillo = new dbFullRastrilloClass();
