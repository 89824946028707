import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import React from "react";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
// import './theme/variables.css';
import SignIn from './pages/login';
import Home from './pages/home';
import { useAuthentication } from './hooks/user';
import { AuthContext } from './contexts/authentication';
import { Box } from '@mui/material';
import Admin from './pages/admin';
import Notification from './components/notifications';
import LayoutUser from './layouts/layout.user';

setupIonicReact();

function CheckAuth(props: {authorized?: React.ReactNode, unauthorized?: React.ReactNode}) {
  const auth = React.useContext(AuthContext);

  if(auth.authorized) return <>{props.authorized}</>;
  return <>{props.unauthorized}</>;
}

const App: React.FC = () => {
  const authentication = useAuthentication();

  return (
    <Notification>
      <AuthContext.Provider value={authentication}>
        <BrowserRouter>
          {/* <Box sx={{width: "100vw", height: "100vh"}}> */}
            <Route exact path="/">
              <Redirect to="/signin"/>
            </Route>
            <Route exact path="/signin">
              <CheckAuth
                unauthorized={<SignIn/>}
                authorized={<Redirect to="/home"/>}
              />
            </Route>
            <Route component={() => {
              if(authentication.authorized) {
                return (
                  <LayoutUser userData={authentication.user}>
                    <Route exact path="/admin">
                      <Admin/>
                    </Route>
                    <Route exact path="/home">
                      <Home />
                    </Route>
                  </LayoutUser>
                )
              }
              return <Redirect to="signin"/>
            }}>
            </Route>
          {/* </Box> */}
        </BrowserRouter>
      </AuthContext.Provider>
    </Notification>
  )
  
  // return (
  //   <Notification>
  //     <Box sx={{width:"100vw", height:"100vh"}}>
  //       <IonApp>
  //         <AuthContext.Provider value={authentication}>
  //           <IonReactRouter>
  //             <IonRouterOutlet>
  //                 <Route exact path="/">
  //                   <Redirect to="/signin" />
  //                 </Route>
  //                 <Route exact path="/home">
  //                   <CheckAuth
  //                     authorized={<Home />}
  //                     unauthorized={<Redirect to="/signin" />}
  //                   />
  //                 </Route>
  //                 <Route exact path="/admin">
  //                   <CheckAuth
  //                     authorized={<Admin />}
  //                     unauthorized={<Redirect to="/signin" />}
  //                   />
  //                 </Route>
  //                 <Route exact path="/signin">
  //                   <CheckAuth
  //                     authorized={<Redirect to="/home" />}
  //                     unauthorized={<SignIn/>}
  //                   />
  //                 </Route>
  //             </IonRouterOutlet>
  //           </IonReactRouter>
  //         </AuthContext.Provider>
  //       </IonApp>
  //     </Box>
  //   </Notification>
  // )
};

export default App;
