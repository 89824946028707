import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, SelectChangeEvent } from "@mui/material";
import { RadioInput } from "../../../models/components";
import React from "react";
import { FormStructContext } from "../../../contexts/form_struct";
import {CircularProgress} from "@mui/material";


export default function RadioInputComponent(props: RadioInput) {
    const formData = React.useContext(FormStructContext);

    const options = props.options.map((opt, index) => ({...opt, value: String(index), label: opt.label ?? opt.value}))
    
    function HandlerOnChange(ev: SelectChangeEvent<unknown>) {
        const option = props.options[Number(ev.target.value)];

        if(option.jump) formData.reserveJump(option.jump);
        formData.setVar(props.name, option.value);
    }

    const value = props.options.findIndex(i => i.value === formData.vars[props.name]);

    React.useEffect(() => {
        if(props.value && (formData.vars[props.name] === undefined)) {
            formData.setVar(props.name, props.value);
        }
    })

    return (
        <FormControl>
            {props.label && <FormLabel>{props.label}</FormLabel>}
            <RadioGroup
                name={props.name}
                value={String(value)}
                onChange={HandlerOnChange}
            >
                {options.map((opt, index) =>
                    <FormControlLabel key={String(index)} value={index} control={<Radio required={props.required}/>} label={opt.label} />
                )}
                {props.loading &&
                    <Box py={1} display="flex" justifyContent="center" alignItems="center">
                        <CircularProgress size={15}/>
                    </Box>
                }
            </RadioGroup>
        </FormControl>
    )
}