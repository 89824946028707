import React from "react";
import { SwitchInput } from "../../../models/components";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import { FormStructContext } from "../../../contexts/form_struct";

export default function SwitchInputComponent(props: SwitchInput) {
    const formData = React.useContext(FormStructContext);
    const checked = Boolean(formData.vars[props.name]);
    
    function HandlerOnChange() {
        formData.setVar(props.name, !checked)
    }

    React.useEffect(() => {
        if(props.value && (formData.vars[props.name] === undefined)) {
            formData.setVar(props.name, props.value);
        }
    }, [])
    
    return (
        <FormGroup>
            <FormControlLabel 
                label={props.label || ""}
                control={
                <Switch 
                    name={props.name}
                    checked={checked} 
                    onChange={HandlerOnChange} 
                    required={props.required}
            />} />
        </FormGroup>
    )
}