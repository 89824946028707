import { API_CIUDAD, API_EDIFICIO, API_ESTADO, API_FRANQUICIA, API_MUNICIPIO, API_NOMENCLATURA, API_SECTOR, API_UBICACION, API_UBICACION_SECTOR, API_UBICACION_ZONA, API_URBANIZACION, API_ZONAS } from "../../models/services";
import { environment } from "../../environments/enviroment.prod";
import { SeguridadDatos } from "../../lib/bscript";
import dbFull from "../../lib/dbfull";

export default class UbicacionesOnlineClass {
    private ciudades: undefined | API_CIUDAD[];
    private estados: undefined | API_ESTADO[];
    private sectores: undefined | API_SECTOR[];
    private municipios: undefined | API_MUNICIPIO[];
    private edificios: undefined | API_EDIFICIO[];
    private franquicias: undefined | API_FRANQUICIA[];
    private nomenclaturas: undefined | API_NOMENCLATURA[];
    private zonas: undefined | API_ZONAS[];
    private urbanizaciones: undefined | API_URBANIZACION[];
    private ubicaciones: undefined | API_UBICACION[];
    private ubicaciones_sector: undefined | API_UBICACION_SECTOR[];
    private ubicaciones_zonas: undefined | API_UBICACION_ZONA[];
    // public value: API_UBICACIONES | undefined;
  
    private dbFullCiudad: dbFull<API_CIUDAD>;
    private dbFullEstado: dbFull<API_ESTADO>;
    private dbFullSector: dbFull<API_SECTOR>;
    private dbFullMunicipio: dbFull<API_MUNICIPIO>;
    private dbFullNomenclatura: dbFull<API_NOMENCLATURA>;
    private dbFullUbicacion: dbFull<API_UBICACION>;
    private dbFullUbicacionSector: dbFull<API_UBICACION_SECTOR>;
  
    constructor() {
        const db = SeguridadDatos.decrypt(environment.ubicaciones.dbSae);
        
        this.dbFullCiudad = new dbFull(db, "ciudad");
        this.dbFullEstado = new dbFull(db, "estado");
        this.dbFullSector = new dbFull(db, "sector");
        this.dbFullMunicipio = new dbFull(db, "municipio");
        this.dbFullNomenclatura = new dbFull(db, "nomenclatura");
        this.dbFullUbicacion = new dbFull(db, "ubicacion");
        this.dbFullUbicacionSector = new dbFull(db, "ubicacion_sector");
    }
  
    ReduceMultiples<type>(arr: type[], callback:(v1: type, v2: type) => Boolean): type[] {
        var x = 0;
        var r;
        while(x < arr.length) {
          const munX = arr[x];
          r = false;
  
          for(let y = x+1; y < arr.length; y++) {
            const munY = arr[y];
  
            r = !callback(munX, munY)
            if(r) break;
          }
  
          if(r) arr.splice(x, 1);
          else x++
        }
  
        return arr;
    }
  
    async GetCiudades() {
        var ciudades: API_CIUDAD[] | undefined = this.ciudades;
  
        if(!ciudades) {
          var estados: API_ESTADO[] = await this.GetEstados();
          ciudades = await this.dbFullCiudad.GET("find-all-info");
  
          console.log(ciudades)
  
    
          // filtrar lo ingenesario
          this.ciudades = ciudades = ciudades!
              .filter(item => item.nombre_ciudad !== "POR COLOCAR" && estados.some(est => est.id_esta === item.id_esta))
              .map(item => ({...item, nombre_ciudad: item.nombre_ciudad.trim().toUpperCase()}))
        }
  
          return ciudades;
    }
  
    async GetOnlyCiudad(id_ciudad: string) {
        var ciudades: API_CIUDAD[] = await this.GetCiudades();
  
        return ciudades.find(city => city.id_ciudad === id_ciudad);
    }
  
  
  
    async GetEstados() {
        var estados: API_ESTADO[] | undefined = this.estados;
  
        if(!estados) {
          estados = await this.dbFullEstado.GET("find-all-info");
          
          // filtrar lo ingenesario
          this.estados = estados = this.ReduceMultiples(
            estados!.filter(item => item.nombre_esta !== "POR COLOCAR" && item.id_pais === "PA0001" && item.nombre_esta !== "VENEZUELA").map(item => ({...item, nombre_esta: item.nombre_esta.trim().toUpperCase()})),
            (v1, v2) => (v1.nombre_esta !== v2.nombre_esta) // PA0001 == venezuela
            )
          }      
          
          return estados;
    }
        
    async GetEstado(id_ciudad: string) {
          var ciudad = await this.GetOnlyCiudad(id_ciudad);
          var estados = await this.GetEstados();
          
          return estados.find(state => ciudad?.id_esta === state.id_esta);
    }
        
    async GetOnlyEstado(id_estado: string) {
          var estados = await this.GetEstados();
        return estados.find(state => state.id_esta === id_estado);
    }
  
  
  
    async GetMunicipios(id_estado?: string) {
        var municipios: API_MUNICIPIO[] | undefined = this.municipios;
        
        if(!municipios) {
          municipios = await this.dbFullMunicipio.GET("find-all-info");
  
          this.municipios = municipios = this.ReduceMultiples(
            municipios!.filter(item => item.nombre_mun !== "POR COLOCAR").map(item => ({...item, nombre_mun: item.nombre_mun.trim().toUpperCase()})),
            (v1, v2) => !(v1.nombre_mun === v2.nombre_mun && v1.id_esta === v2.id_esta)
          )
        }
  
        return id_estado ? municipios.filter(mun => mun.id_esta === id_estado) : municipios;
    }
  
    async GetOnlyMunicipio(id_municipio: string) {
        var municipios = await this.GetMunicipios()
        return municipios.find(municipality => municipality.id_mun === id_municipio);
    }
  
  
  
    async GetSectores(id_municipio?: string) {
        var sectores: API_SECTOR[] | undefined = this.sectores;
  
        if(!sectores) {
          sectores = await this.dbFullSector.GET("find-all-info");
  
          this.sectores = sectores = this.ReduceMultiples(
            sectores!.filter(item => item.nombre_sector !== "" && item.nombre_sector !== "").map(item => ({...item, nombre_sector: item.nombre_sector.trim().toUpperCase()})),
            (v1, v2) => (v1.nombre_sector !== v2.nombre_sector || v1.id_ciudad !== v2.id_ciudad)
          )
        }
  
        return id_municipio ? sectores.filter(sector => sector.id_mun === id_municipio) : sectores;
    }
  
    async GetOnlySector(id_sector: string) {
        var sectores = await this.GetSectores();
        return sectores.find(sector => sector.id_sector === id_sector);
    }
  
  
    // GetZonas(id_sector: string) {
      //   var sector = this.sectores.find(sector => sector.id_sector === id_sector);
      //   // console.log("SECTOR ---> ", sector)
      //   return this.zonas.filter(zonas => sector?.id_zona === zonas.id_zona);
    // }
  
    async GetUbicacionesSector(id_sector?: string) {
        var ubicacionesSector: API_UBICACION_SECTOR[] | undefined = this.ubicaciones_sector;
  
        if(!ubicacionesSector) {
          this.ubicaciones_sector = ubicacionesSector = await this.dbFullUbicacionSector.GET("find-all-info");
        }
  
        return id_sector ? ubicacionesSector!.filter(u => u.id_sector === id_sector) : ubicacionesSector!;
    }
  
    async GetUbicaciones(id_sector?: string) {
        var ubicaciones: API_UBICACION[] | undefined = this.ubicaciones;
        var ubicaciones_Sector = await this.GetUbicacionesSector();
  
        if(!ubicaciones) {
          this.ubicaciones = ubicaciones = await this.dbFullUbicacion.GET("find-all-info");
        }
  
        return (id_sector ? ubicaciones!.filter(ubi => ubicaciones_Sector.some(sect => sect.id_ubi === ubi.id_ubi)) : ubicaciones)!;
    }
  
    async GetNomenclaturas(id_ubi?: string) {
        var nomenclaturas: API_NOMENCLATURA[] | undefined = this.nomenclaturas;
        var ubicaciones = await this.GetUbicaciones();
  
        if(!nomenclaturas) {
          this.nomenclaturas = nomenclaturas = await this.dbFullNomenclatura.GET("find-all-info");
        }
  
        return id_ubi ? nomenclaturas!.filter(nomen => ubicaciones.some(ubi => ubi.id_ubi === id_ubi && nomen.id_nomen === ubi.id_nomen)) : nomenclaturas!;
    }
  
    async GetOnlyUbicacion(id_ubicacion: string) {
        var ubicaciones = await this.GetUbicaciones();
        return ubicaciones.find(ubi => ubi.id_ubi === id_ubicacion);
    }
  
    async GetOnlyNomenclatura(id_nomenclatura: string) {
        var nomenclaturas = await this.GetNomenclaturas();
        return nomenclaturas.find(nomen => nomen.id_nomen === id_nomenclatura);
    }
  
    async GetOnlyUbicacionZona(id_ubicacion: string) {
        var ubi = await this.GetOnlyUbicacion(id_ubicacion);
        var nomen = await this.GetOnlyNomenclatura(ubi?.id_nomen || "");
  
        if(ubi && nomen) {
          var name = ubi.nombre_ubi;
  
          if(!name.includes(nomen.nombre_nomen) && !RegExp(`\\b${nomen.abrev_nomen}\\.?\\b`).test(name)) {
            name = nomen.nombre_nomen + " " + name;
          }
  
          return {
            nombre_ubicacion_zona: name,
            ...ubi,
            ...nomen,
          }
        }
    }
  
    async GetUbicacionesZonas(id_sector: string) {
        var sector = await this.GetOnlySector(id_sector);
        var ubicacionesSector = await this.GetUbicacionesSector(sector?.id_sector);
  
        var __ubicaciones = await this.GetUbicaciones();
        var __nomenclaturas = await this.GetNomenclaturas();
  
        var ubicaciones = (ubicacionesSector?.map(ubi => __ubicaciones.find(ubi2 => (ubi.id_ubi === ubi2.id_ubi))) || []).filter(ubi => ubi !== undefined) as API_UBICACION[]
        var nomenclaturas = ubicaciones?.map(ubi => __nomenclaturas.find(nomen => nomen.id_nomen === ubi?.id_nomen)) as API_NOMENCLATURA[]
  
        // console.log("UBICACIONES-->", ubicaciones)
        // console.log("NOMENCLATURAS-->", nomenclaturas)
        
        var ubicacionesDisponibles: Array<API_UBICACION_ZONA> = [];
  
        for(let x = 0; x < ubicaciones.length && x < nomenclaturas.length; x++) {
          var name = ubicaciones[x].nombre_ubi;
  
          if(!name.includes(nomenclaturas[x].nombre_nomen) && !RegExp(`\\b${nomenclaturas[x].abrev_nomen}\\.?\\b`).test(name)) {
            name = nomenclaturas[x].nombre_nomen + " " + name;
          }
  
          ubicacionesDisponibles.push({
            nombre_ubicacion_zona: name,
            ...ubicaciones[x],
            ...nomenclaturas[x],
          })
        }
  
        return ubicacionesDisponibles;
    }
}