import React from "react";
import { SliderInput } from "../../../models/components";
import { Box, Slider, Typography } from "@mui/material";
import { FormStructContext } from "../../../contexts/form_struct";

export default function SliderInputComponent(props: SliderInput) {
    const formData = React.useContext(FormStructContext);
    const value = Number(formData.vars[props.name] || 0);

    const valueLabel = "Valor: "+value;
    
    function HandlerOnChange(ev: Event, value: number | number[]) {
        formData.setVar(props.name, Number(value));
    }
    
    React.useEffect(() => {
        if(props.value && (formData.vars[props.name] === undefined)) {
            formData.setVar(props.name, props.value);
        }
    }, [])
    
    return (
        <Box>
            {props.label &&
                <Typography id="non-linear-slider" gutterBottom>
                    {props.label}
                </Typography>
            }
            <Slider
                name={props.name}
                value={value}
                min={props.min}
                max={props.max}
                step={props.step}
                // scale={calculateValue}
                // getAriaValueText={valueLabel}
                valueLabelFormat={valueLabel}
                onChange={HandlerOnChange}
                valueLabelDisplay="auto"
            aria-labelledby="non-linear-slider"
            />
        </Box>
    )
}