import React from "react";
import { AuthInterface } from "../models/authentication";

function NoContext() {
    throw(new Error("No se encuentra definido el contexto"))
}

export const AuthContext = React.createContext<AuthInterface>({
    mode: "offline",
    authorized: false,
    loading: false,
    setMode: NoContext,
    Login: async () => Boolean(NoContext()),
    Logout: NoContext
})