class LocalStorageClass {
    constructor() { }

    Set(name: string, obj: unknown) {
        try {
            localStorage.setItem(name, JSON.stringify(obj));
        }
        catch(_err) { }
    }

    Get<type = any>(name: string): type | undefined {
        try {
            return JSON.parse(localStorage.getItem(name) ?? "");
        }
        catch(_err) { }
    }

    Remove(name: string) {
        try {
            localStorage.removeItem(name);
        }
        catch(_err) { }
    }
}

export const LocalStorageService = new LocalStorageClass();