import React from "react";
import { CheckboxInput } from "../../../models/components";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { FormStructContext } from "../../../contexts/form_struct";

export default function CheckboxInputComponent(props: CheckboxInput) {
    const formData = React.useContext(FormStructContext);
    const checked = Boolean(formData.vars[props.name]);
    
    function HandlerOnChange() {
        formData.setVar(props.name, !checked)
    }

    React.useEffect(() => {
        if(props.value && (formData.vars[props.name] === undefined)) {
            formData.setVar(props.name, props.value);
        }
    })
    
    return (
        <FormGroup>
            <FormControlLabel 
                label={props.label || ""}
                control={
                <Checkbox 
                    name={props.name}
                    disabled={props.disabled}
                    required={props.required}
                    checked={checked} 
                    onChange={HandlerOnChange} 
            />} />
        </FormGroup>
    )
}