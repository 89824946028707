export const environment = {
    keybScript: 'T0rNaDoK4tr1Na?RTgcNmhKU=',
    
    
    crm: {
        url: "https://crm.thomas-talk.me/api/"
    },

    ubicacionesthomas: {
        token: 'U2FsdGVkX19Hj+4KDNHgzY304B3BIfQTQNm8C8zv2MPb5e1FgJNBKf6mjzHB8mnc',
        getZoneMethod: 'U2FsdGVkX18O9p3jmsVzR1BOUtNn8M46KdR3TXigC1U=',
        platform: 'U2FsdGVkX18IAt3HeWLt/FLQXKERQ+tiQ+LWY0QFULM=',
    },

    ubicaciones: {
        dbSae: "U2FsdGVkX1+KihihY2v+voHASlZIpaEf+ADVsLjHYDIRnw1ceiuKF05L4TuFDseP",
    },

    rastrillo: {
        db: 'U2FsdGVkX19gf0Rf6JLBHx/Rns3yLEYXyZESv+LrDygMq+HtsvdksZxJLP3EAF5x',
        table: 'U2FsdGVkX1+YW18+H79r/MC5KAHrzgPUEoK27cbCqmI7HDMQ9oGxt6uz5Co3bf3j',
    },

    dbFull: {
      url: "https://dbfull.thomas-talk.me/api/",
      authdbFUll: 'Basic ' + btoa('Onur:L4V1d43NsuPl3N1tud**=BghYjLaQeTB'),
      authdbFUllbuffer: 'Basic T251cjpMNFYxZDQzTnN1UGwzTjF0dWQqKj1CZ2hZakxhUWVUQg==',
      tokendbFull: '81N2vjsIqq39qjGoEmDmMtjLqW7gLDA7vBV-Ffwuhwf-evejDaRGMrdSASny480GVOl7fcYfh21xfcpJWZ8VzQBHf0chPGOhyo9w3zJQ8OXEYGxwzxCU1gDplt3ebE4wDCkoujh4284bTkzz52AbNudtcR1HBq5_xU3mL5IJ4pqbeiFOJVa9',
    }
};