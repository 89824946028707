import React from "react";
import { FormStructContextInterface } from "../models/contexts";
import { FormStructPageData } from "../models/components";


export function useFormStruct(Pages: FormStructPageData[], defaultIndex?: number): FormStructContextInterface {
    const [index, __setIndex] = React.useState(defaultIndex ?? 0);
    const [vars, setVars] = React.useState({});
    const [changing, setChanging] = React.useState(false);
    const [indexReserved, setIndexReserved] = React.useState<number | null>(null);
    const [history, setHistory] = React.useState<number[]>([]);
    const maxIndex = Pages.length;
    const finished = index >= maxIndex;

    function addHistory() {
        setHistory([...history, index])
    }

    function setIndex(i: number) {
        if(!changing) {
            setChanging(true);
            setTimeout(() => {
                setIndexReserved(null);
                setChanging(false);
                __setIndex(i);
            }, 1000);
        }
    }
    
    function Next() {
        if(index <= maxIndex) {
            setIndex(indexReserved ?? (index+1));
            addHistory();
        }
    }

    function Back() {
        if(index >= 0) {
            const indexHistory = history.pop();

            if(typeof indexHistory === "number") {
                setIndex(indexHistory);
                setHistory([...history]);
            }
        }
    }

    function setVar(name: string, value: unknown) {
        setVars({...vars, [name]: value});
    }

    function jumpTo(id: string) {
        let index;

        switch(id) {
            case "finish":
                index = maxIndex;
                break;

            case "back":
                Back();
                return;

            default:
                index = Pages.findIndex(page => page.id === id);
        }

        if(index !== -1) {
            setIndex(index);
            addHistory();
        }
        else Next();
    }

    function reserveJump(id: string) {
        let index: number;

        switch(id) {
            case "finish":
                index = maxIndex;
                break;

            case "back":
                Back();
                return;

            default:
                index = Pages.findIndex(page => page.id === id);
        }

        if(index !== -1) {
            setIndexReserved(index);
        }
    }

    function reset() {
        setVars({});
        setIndex(defaultIndex ?? 0);
        setHistory([]);
    }

    return {
        vars: vars,
        changing,
        index,
        setVar,
        Back,
        Next,
        maxIndex,
        jumpTo,
        reserveJump,
        reset,
        finished
    }
}