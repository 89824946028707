import { environment } from "../environments/enviroment.prod";


const CryptoJS = require("crypto-js");


export class SeguridadDatos {
    
    //Encripta la los datos
    static encrypt(str: string){
      let encrypted = CryptoJS.AES.encrypt(str, environment.keybScript, {
        keySize: 16,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      });
    //   console.log(str)
      return encrypted.toString();
    }

    // public static decrypt(str: string) {
    //     let decrypted = CryptoJS.AES.decrypt(str, environment.keybScript, {
    //       keySize: 16,
    //       mode: CryptoJS.mode.ECB,
    //       padding: CryptoJS.pad.Pkcs7,
    //     });
    //     return decrypted.toString(CryptoJS.enc.Utf8);;
    // }

  static decrypt(str: string) {
    let decrypted = CryptoJS.AES.decrypt(str, environment.keybScript, {
      keySize: 16,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }).toString(CryptoJS.enc.Utf8);

    return decrypted.toString()
  }

    public static EncrypObj(obj: any) {
        const newObj: any = {};

        for(let keyName in obj) {
            if(obj[keyName] instanceof Array || obj[keyName] instanceof Object) {
                obj[keyName] = this.EncrypObj(obj[keyName]);
            }
            else newObj[keyName] = SeguridadDatos.encrypt(String(obj[keyName]));
        }
        return newObj;
    }
}