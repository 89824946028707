import React from "react";
import { FormStructContextInterface } from "../models/contexts";

function NoContext() {
    throw(new Error("Contexto FormStruct no definido"))
}

export const FormStructContext = React.createContext<FormStructContextInterface>({
    index: 0,
    vars: {},
    changing: false,
    maxIndex: 0,
    finished: true,
    jumpTo: NoContext,
    setVar: NoContext,
    Back: NoContext,
    Next: NoContext,
    reserveJump: NoContext,
    reset: NoContext,
})