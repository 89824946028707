import React from "react";
import { AuthContext } from "../contexts/authentication";
import LayoutUser from "../layouts/layout.user";
import AdminRastrillo from "../app/admin_rastrillo";


export default function Admin() {
    const auth = React.useContext(AuthContext);

    if(auth.authorized && auth.admin) {
        return <AdminRastrillo userData={auth.user} admin={auth.admin}/>
    }
    return null;
}