import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateInput } from '../../../models/components';
import { FormStructContext } from '../../../contexts/form_struct';
import dayjs from 'dayjs';

function InterpretDateString(text?: string): string | undefined {
    if(text) {
        if(text === "now") {
            return (new Date()).toISOString().replace(/T(\w|\W)+/, "");
        }
        else {
            try {
                return (new Date(text)).toISOString().replace(/T(\w|\W)+/, "");
            }
            catch(_err) { }
        }
    }
}

export default function DateInputComponent(props: DateInput) {
    const formData = React.useContext(FormStructContext)
    const value = dayjs(formData.vars[props.name] as string ?? null);
    const min = dayjs(InterpretDateString(props.min) ?? null);
    const max = dayjs(InterpretDateString(props.max) ?? null);

    function HandlerOnChange(value: any) {
        formData.setVar(props.name, (new Date(value)).toISOString().replace(/T(\w|\W)+/, ""));
    }

    React.useEffect(() => {
        if(props.value && (formData.vars[props.name] === undefined)) {
            formData.setVar(props.name, InterpretDateString(props.value));
        }
    })

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker 
                sx={{width: "100%"}}
                minDate={min}
                maxDate={max}
                onChange={HandlerOnChange}
                value={value}
                disabled={props.disabled}
            />
        </LocalizationProvider>
    );
}





// import * as React from 'react';
// import { Dayjs } from 'dayjs';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { DateInput } from '../../../models/components';

// export default function DateInputComponent(props: DateInput) {
//   const [value, setValue] = React.useState<Dayjs | null>(null);

//   new Dayjs()

//   return (
//     <LocalizationProvider dateAdapter={AdapterDayjs}>
//       <DemoContainer components={['DatePicker']}>
//         <DatePicker value={value} onChange={(newValue) => setValue(newValue)} />
//       </DemoContainer>
//     </LocalizationProvider>
//   );
// }