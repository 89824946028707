import React from "react";
import { AuthContext } from "../contexts/authentication";
import LayoutUser from "../layouts/layout.user";
import { UserData } from "../models/authentication";
import { Backdrop, Box, Button, Card, CardHeader, CircularProgress, Divider, Fade, IconButton, Modal, Pagination, Paper, Stack, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography, useTheme } from "@mui/material";
import { useAdminRastrillo } from "../hooks/admin_rastrillo";
import { DatosRastrillo } from "../models/services";
import VisibilityIcon from '@mui/icons-material/Visibility';

import CancelIcon from '@mui/icons-material/Cancel';
// import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
// import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
// import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
// import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

const styleModal = {
    position: 'absolute' as const,
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    flexDirection:"column",
    display:"flex",
    maxWidth: 600,
    boxShadow: 24,
    overflowY:"auto",
    height:{
        xs:"100%",
        sm:"auto"
    }
  };

export default function AdminRastrillo(props: { userData: UserData, admin: string[]}) {
    const [open, setOpen] = React.useState(false);
    const [details, setDetails] = React.useState<null | DatosRastrillo>(null)
    const adminData = useAdminRastrillo(props.userData.email);
    const theme = useTheme();

    const detalles: {[key in keyof DatosRastrillo]?: DatosRastrillo[key]} = details ?? {correo: "", Vendedor:"", FechaVisita:""};

    for(let key in detalles) {
        if((detalles as any)[key] === "N/A") delete (detalles as any)[key as any];
    }
    
    return (
        <Box p={2} py={5} style={{backgroundImage: "url('/background.png')", overflow:"auto", height:"100%"}}>
            <Card>
                <CardHeader title="Personas"/>
                <Box>
                    <Table key={`${adminData.page}-${adminData.loading}`} sx={{
                        animation: "1s ANIM-OPENING"
                    }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Vendedor</TableCell>
                                <TableCell sx={{display: {xs:"none", md: "table-cell"}}}>Correo</TableCell>
                                <TableCell sx={{display: {xs:"none", sm: "table-cell"}}}>Dirección fiscal</TableCell>
                                <TableCell align="right">Fecha</TableCell>
                                {/* <TableCell>Email</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!adminData.loading && adminData.elements.map(element => props.admin.some(ad => (element.correo === ad || element.correo === props.userData.email)) &&
                                    <TableRow key={String(element.IdRegistro)}>
                                        <TableCell>{element.Vendedor}</TableCell>
                                        <TableCell sx={{display: {xs:"none", md: "table-cell"}}}>{element.correo}</TableCell>
                                        <TableCell sx={{display: {xs:"none", sm: "table-cell"}}}>{element.DireccionFiscal}</TableCell>
                                        <TableCell style={{whiteSpace:"nowrap"}} align="right">
                                            {element.FechaVisita}
                                        </TableCell>
                                        <TableCell align="right">
                                            <IconButton onClick={() => {setDetails(element); setOpen(true)}}>
                                                <VisibilityIcon sx={{color:theme.palette.primary.contrastText}}/>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>    
                            )}
                            {adminData.loading &&
                                <TableRow>
                                    <TableCell align="center" sx={{padding: "4em 1em", display: {xs:"table-cell", sm: "none"}}} colSpan={4}>
                                        <CircularProgress color="secondary"/>
                                    </TableCell>
                                    <TableCell align="center" sx={{padding: "4em 1em", display: {xs:"none", md: "table-cell"}}} colSpan={5}>
                                        <CircularProgress color="secondary"/>
                                    </TableCell>
                                    <TableCell align="center" sx={{padding: "4em 1em", display: {xs:"none", sm: "table-cell", md:"none"}}} colSpan={6}>
                                        <CircularProgress color="secondary"/>
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </Box>
                <Box display="flex" justifyContent="flex-end">
                    <TablePagination
                        component="div"
                        count={adminData.count}
                        page={adminData.page}
                        onPageChange={(ev, p) => adminData.setPage(p)}
                        rowsPerPage={adminData.limit}
                        labelRowsPerPage="Col"
                        rowsPerPageOptions={[3, 5, 10, 20, 30]}
                        onRowsPerPageChange={(e) => adminData.setLimit(parseInt(e.target.value, 10))}
                    />
                </Box>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={open}
                        onClose={() => setOpen(false)}
                        closeAfterTransition
                        sx={{display:"flex", justifyContent:"center", alignItems:"center"}}
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                        }}
                    >
                        <Fade in={open}>
                            <Box sx={styleModal} component={Card} style={{maxHeight: "100vh", overflow: "auto"}}>
                                <Box py={2.5} px={2} display="flex" sx={{justifyContent:"space-between"}}>
                                    <Typography variant="h5">Vista previa</Typography>
                                    <Button
                                        color="error"
                                        onClick={() => setOpen(false)}
                                        startIcon={<CancelIcon/>}
                                    >
                                        Cerrar
                                    </Button>
                                </Box>
                                <Divider/>
                                <Table>
                                    <TableBody>
                                        {detalles.Vendedor &&
                                            <TableRow>
                                                <TableCell>
                                                    <strong>Vendedor</strong>
                                                </TableCell>
                                                <TableCell>{detalles.Vendedor}</TableCell>
                                            </TableRow>
                                        }
                                        {detalles.FechaVisita &&
                                            <TableRow>
                                                <TableCell>
                                                    <strong>Fecha de Visita</strong>
                                                </TableCell>
                                                <TableCell>{detalles.FechaVisita}</TableCell>
                                            </TableRow>
                                        }
                                        {detalles.Ciudad &&
                                            <TableRow>
                                                <TableCell>
                                                    <strong>Ciudad</strong>
                                                </TableCell>
                                                <TableCell>{detalles.Ciudad}</TableCell>
                                            </TableRow>
                                        }
                                        {detalles.Zona &&
                                            <TableRow>
                                                <TableCell>
                                                    <strong>Zona</strong>
                                                </TableCell>
                                                <TableCell>{detalles.Zona}</TableCell>
                                            </TableRow>
                                        }
                                        {detalles.DireccionFiscal &&
                                            <TableRow>
                                                <TableCell>
                                                    <strong>Dirección Fiscal</strong>
                                                </TableCell>
                                                <TableCell>{detalles.DireccionFiscal}</TableCell>
                                            </TableRow>
                                        }
                                        {detalles.tipoResidencia &&
                                            <TableRow>
                                                <TableCell>
                                                    <strong>Tipo Residencia</strong>
                                                </TableCell>
                                                <TableCell>{detalles.tipoResidencia}</TableCell>
                                            </TableRow>
                                        }
                                        {detalles.NombreResidencia &&
                                            <TableRow>
                                                <TableCell>
                                                    <strong>Nombre edificio y/o Torre</strong>
                                                </TableCell>
                                                <TableCell>{detalles.NombreResidencia}</TableCell>
                                            </TableRow>
                                        }
                                        {detalles.ContactoEfectivo &&
                                            <TableRow>
                                                <TableCell>
                                                    <strong>Contacto efectivo</strong>
                                                </TableCell>
                                                <TableCell>{detalles.ContactoEfectivo}</TableCell>
                                            </TableRow>
                                        }
                                        {detalles.InteresEnContratar &&
                                            <TableRow>
                                                <TableCell>
                                                    <strong>Interés en contratar</strong>
                                                </TableCell>
                                                <TableCell>{detalles.InteresEnContratar}</TableCell>
                                            </TableRow>
                                        }
                                        {detalles.NombreCliente &&
                                            <TableRow>
                                                <TableCell>
                                                    <strong>Nombre Cliente</strong>
                                                </TableCell>
                                                <TableCell>{detalles.NombreCliente}</TableCell>
                                            </TableRow>
                                        }
                                        {detalles.PhoneCliente &&
                                            <TableRow>
                                                <TableCell>
                                                    <strong>Teléfono Cliente</strong>
                                                </TableCell>
                                                <TableCell>{detalles.PhoneCliente}</TableCell>
                                            </TableRow>
                                        }
                                        {detalles.PoseeServicioInternet &&
                                            <TableRow>
                                                <TableCell>
                                                    <strong>Posee servicio internet</strong>
                                                </TableCell>
                                                <TableCell>{detalles.PoseeServicioInternet}</TableCell>
                                            </TableRow>
                                        }
                                        {detalles.ProvedorInternet &&
                                            <TableRow>
                                                <TableCell>
                                                    <strong>Nombre proveedor</strong>
                                                </TableCell>
                                                <TableCell>{detalles.ProvedorInternet}</TableCell>
                                            </TableRow>
                                        }
                                        {detalles.NoContactoEfectivo &&
                                            <TableRow>
                                                <TableCell>
                                                    <strong>Motivo no contacto efectivo</strong>
                                                </TableCell>
                                                <TableCell>{detalles.NoContactoEfectivo}</TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </Box>
                        </Fade>
                    </Modal>
            </Card>
        </Box>
    )
}