import { Box, Card, ThemeProvider, createTheme, styled, useTheme } from "@mui/material";
import { FormStructPageData, FormStructProps } from "../../models/components";
import FormPage from "../form_page";
import { FormStructContext } from "../../contexts/form_struct";
import "./index.css";
import { useFormStruct } from "../../hooks/form_struct";
import React from "react";

const finishedFormDefault: FormStructPageData = {
    title: "Finalizado",
    subtitle: "Encuesta finalizada con éxito",
    type:"none",
    next: null,
    back: null
}

const CardStyled = styled(Card)({
    maxWidth: "450px",
    width: "100%",
    padding: "20px",
    animationDuration: "1s",
    animationName: "ANIM-OPENING",
    animationFillMode:"both",

    "&.changing": {
        animationName: "ANIM-CLOSING"
    }
})

const BackgroundStyled = styled(Box)({
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    width:"100%",
    height:"100%",
    backgroundSize:"cover",
    backgroundPosition:"center"
})

function Background(props: {src?: string, color?: string, children?: React.ReactNode}) {
    const color = props.color || "#800";
    const inheritTheme = useTheme();
    
    const theme = createTheme({
        ...inheritTheme,
        palette: {
            ...inheritTheme?.palette,
            primary: {
                main: color
            }
        }
    });

    return (
        <BackgroundStyled sx={{
            backgroundImage: `${props.src ? `url("${props.src}") ` : ""}`,
        }}>
            <ThemeProvider theme={theme}>
                {props.children}
            </ThemeProvider>
        </BackgroundStyled>
    )
}

export default function FormStruct(props: FormStructProps) {
    const form = useFormStruct(props.formData.main, props.defaultIndex);
    const [finishPage, setFinishPage] = React.useState({...(props.formData.general), ...(props.formData.finished || finishedFormDefault)})
    const [key, setKey] = React.useState(0);
    
    React.useEffect(() => {
        if(form.finished && props.onFinish) {
            props.onFinish(form.vars, finishPage, (newFinish) => {
                setFinishPage(newFinish);
                setTimeout(() => setKey(Math.random()), 0);
            });
        }
    }, [form.finished])
    
    // datos de la página a renderizar
    
    if(form.index < form.maxIndex) {
        const {background, color, ...FormPageData} = {...(props.formData.general), ...props.formData.main[form.index]};
        
        return (
            <Background src={background} color={color}>
                <FormStructContext.Provider value={form}>
                    <CardStyled className={form.changing ? "changing" : ""}>
                        <FormPage key={String(form.index)} formPageData={FormPageData} beforeRendering={props.beforeRendering}/>
                    </CardStyled>
                </FormStructContext.Provider>
            </Background>
        )
    }
    else {
        const {background, color, ...FormPageData} = finishPage;

        return (
            <Background src={background} color={color}>
                <FormStructContext.Provider value={form}>
                    <CardStyled className={form.changing ? "changing" : ""}>
                        <FormPage key={String(key)} formPageData={FormPageData} beforeRendering={props.beforeRendering}/>
                    </CardStyled>
                </FormStructContext.Provider>
            </Background>
        )
    }
}